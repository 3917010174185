import "./styles/passwordReset.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import utils from "../utils/regex";
import { toast } from "react-toastify";
import userService from "../service/userService";
const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const handleResetPassword = async () => {
    setError("");
    if (email === "") {
      setError("Please enter your email");
      return;
    }
    const validateEmail = utils.emailRegex.test(email);
    if (!validateEmail) {
      setError("Please enter a valid email");
      return;
    }
    setDisableBtn(true);
    try {
      const response = await userService.resetPassword(email);
      if (response?.status === 200) {
        toast.success(response.data.message);
        navigate("/login");
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.log(err, "error in reset password");
      setError("Error in resetting password");
    } finally {
      setDisableBtn(false);
    }
  };
  return (
    <>
      <div className="resetPassword-wrapper">
        <div className="title">Reset Password</div>
        <div className="input-wrapper">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        {error && <div className="error">{error}</div>}
        <div className="input-wrapper">
          <button
            className="btn create-game"
            disabled={disableBtn}
            onClick={handleResetPassword}
          >
            Reset Password
          </button>
        </div>

        <div className="forgot-password" onClick={() => navigate("/login")}>
          Back To Login{" "}
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
