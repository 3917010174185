import "./styles/login.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import userService from "../service/userService";
import utils from "../utils/regex";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/reducer";
const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token_dazn");

    if (token) {
      const checkUserRole = async () => {
        try {
          const user = await userService.checkUserRole(token);
          if (user.status === 200 && user.data.tokenIsValid) {
            dispatch(setCurrentUser(user.data.currentUser));
            navigate("/dashboard");
          }
        } catch (err) {
          console.log(err, "error in fetching role login");
        }
      };
      checkUserRole();
    }
  }, []);

  const handleLogin = async () => {
    setError("");
    setDisableBtn(true);
    if (user.email === "" || user.password === "") {
      setError("Please fill all the fields");
      setDisableBtn(false);
      return;
    }

    const validateEmail = utils.emailRegex.test(user.email);
    if (!validateEmail) {
      setError("Please enter a valid email");
      setDisableBtn(false);
      return;
    }

    try {
      const loginUser = await userService.loginUser(user);
      if (loginUser?.status === 200) {
        localStorage.setItem("token_dazn", loginUser.data.token);
        dispatch(setCurrentUser(loginUser.data.user));
        navigate("/dashboard");
      } else {
        toast.error("Credentials d'ont match");
        setError("Contact Main Admin");
      }
    } catch (err) {
      setError("Error in creating admin");
    } finally {
      setDisableBtn(false);
    }
  };
  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="title">Login</div>
          <div className="input-wrapper">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={user.email}
              onChange={(e) => {
                setError("");
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              value={user.password}
              onChange={(e) => {
                setError("");
                setUser({ ...user, [e.target.name]: e.target.value });
              }}
            />
          </div>
          {error && <div className="error">{error}</div>}
          <div className="input-wrapper">
            <button
              className="btn create-game"
              disabled={disableBtn}
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
          <div
            className="forgot-password"
            onClick={() => navigate("/password-reset")}
          >
            Forgot Password ?
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
