import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/activateGame.css";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import moment from "moment-timezone";
import gameService from "../service/gameService";
import { toast } from "react-toastify";
const ActivateGame = () => {
  const [game, setGame] = useState(null);

  const [teamNames, setTeamNames] = useState({
    homeTeam: "",
    awayTeam: "",
  });

  const [gameDuration, setGameDuration] = useState(5);

  const navigate = useNavigate();

  const currentGameRedux = useSelector((state) => state.main.currentGame);

  const teamsRedux = useSelector((state) => state.main.activeTeams);

  useEffect(() => {
    if (currentGameRedux?._id) {
      setGame(currentGameRedux);
      if (teamsRedux?.length) {
        const homeTeam = teamsRedux.find(
          (team) => team._id === currentGameRedux.home_team
        );
        const awayTeam = teamsRedux.find(
          (team) => team._id === currentGameRedux.away_team
        );
        setTeamNames({
          homeTeam: homeTeam?.team_name,
          awayTeam: awayTeam?.team_name,
        });
      }
    }
    // else {
    //   navigate("/login");
    // }
  }, []);

  const handleCounter = (type) => {
    if (type === "plus") {
      // return to 20
      if (gameDuration === 20) return;

      setGameDuration((prev) => prev + 5);
    } else {
      if (gameDuration === 5) return;

      setGameDuration((prev) => prev - 5);
    }
  };
  // Function to get the current date and time in the America/New_York timezone
  const getCurrentDateTime = () => {
    return moment.tz("Europe/Rome").toDate();
  };

  // Function to calculate the end time based on the selected minutes
  // const getEndTime = (selectedMinutes) => {
  //   return moment.tz("Europe/Rome").add(selectedMinutes, "minutes").toDate();
  // };

  const activateGame = async () => {
    const currentTime = getCurrentDateTime();
    // const endTime = getEndTime(gameDuration);

    const data = {
      game_id: game._id,
      activation_time: currentTime,
      duration: gameDuration,
    };

    try {
      const response = await gameService.activateGame(data);
      if (response.status === 200) {
        toast.success("Game activated");
        navigate("/dashboard");
      } else {
        toast.error("Error in activating game");
      }
    } catch (err) {
      console.log(err, "error in activating game");
    }
  };
  return (
    <>
      <div className="activate-game-wrapper">
        <div className="top">
          <div
            className="back"
            style={{ paddingBlockStart: "15px" }}
            onClick={() => navigate("/dashboard")}
          >
            <IoMdArrowBack size={25} />
            <span>Back</span>
          </div>
          <div className="title">Activate Game</div>
        </div>

        <div className="game-details">
          {/* <div className="game-details-title">Game Details:</div> */}

          <div className="game-name">
            Game Name: <span>{game?.game_name}</span>
          </div>
          <div className="game-home-team">
            Home team:
            <span>{teamNames?.homeTeam}</span>
          </div>
          <div className="game-away-team">
            Away team: <span>{teamNames?.awayTeam}</span>
          </div>
        </div>

        <div className="game-duration">
          <div className="game-duration-title">Set Duration:</div>

          <div className="counter">
            <div className="plus" onClick={() => handleCounter("plus")}>
              <CiSquarePlus size={50} color="blue" />
            </div>

            <div className="duration">{gameDuration}</div>

            <div className="minus" onClick={() => handleCounter("minus")}>
              <CiSquareMinus size={50} color="red" />
            </div>
          </div>
        </div>

        <div className="activate-btn" onClick={activateGame}>
          <button>Activate</button>
        </div>
      </div>
    </>
  );
};

export default ActivateGame;
