import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import gameService from "../service/gameService";
import { IoMdArrowBack } from "react-icons/io";
import moment from "moment";

import "./styles/statistics.css";
import { toast } from "react-toastify";
const Statistics = () => {
  const [fullGameData, setFullGameData] = useState(null);
  const currentGameRedux = useSelector((state) => state.main.currentGame);
  const teamsRedux = useSelector((state) => state.main.activeTeams);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchFullGameData = async () => {
      try {
        const response = await gameService.getGameStats(currentGameRedux._id);
        if (response.status === 200) {
          const {
            data: { poll, stats },
          } = response;

          const homeTeam = teamsRedux.find(
            (team) => team._id === currentGameRedux.home_team
          ).team_name;
          const awayTeam = teamsRedux.find(
            (team) => team._id === currentGameRedux.away_team
          ).team_name;

          const fullData = {
            gameName: currentGameRedux.game_name,
            totalVotes: stats.totalVotes,
            homeTeamName: homeTeam,
            awayTeamName: awayTeam,
            pollEnded: currentGameRedux.survey_stop_time,
            homeTeamId: currentGameRedux.home_team,
            awayTeamId: currentGameRedux.away_team,
            homeTeamColor: currentGameRedux.home_team_color,
            awayTeamColor: currentGameRedux.away_team_color,
            goalkeeper_home_team: {
              name: poll.goalkeeper_home_team,
              votes: stats.goalkeeperHome.votes,
              percentage: stats.goalkeeperHome.percentage,
            },
            goalKeeper_away_team: {
              name: poll.goalkeeper_away_team,
              votes: stats.goalkeeperAway.votes,
              percentage: stats.goalkeeperAway.percentage,
            },
            defender_home_team: {
              name: poll.defender_home_team,
              votes: stats.defenderHome.votes,
              percentage: stats.defenderHome.percentage,
            },
            defender_away_team: {
              name: poll.defender_away_team,
              votes: stats.defenderAway.votes,
              percentage: stats.defenderAway.percentage,
            },
            midfielder_home_team: {
              name: poll.midfielder_home_team,
              votes: stats.midfielderHome.votes,
              percentage: stats.midfielderHome.percentage,
            },
            midfielder_away_team: {
              name: poll.midfielder_away_team,
              votes: stats.midfielderAway.votes,
              percentage: stats.midfielderAway.percentage,
            },
            striker_home_team: {
              name: poll.striker_home_team,
              votes: stats.strikerHome.votes,
              percentage: stats.strikerHome.percentage,
            },
            striker_away_team: {
              name: poll.striker_away_team,
              votes: stats.strikerAway.votes,
              percentage: stats.strikerAway.percentage,
            },
          };

          setFullGameData(fullData);
        } else {
          console.warn(`Unexpected error ${response.message}`);
          toast.error(response.data.message);
        }
      } catch (err) {
        console.log(err, "error in fetching full game data");
      }
    };

    if (currentGameRedux?._id) {
      fetchFullGameData();
    } else {
      navigate("/login");
    }
  }, [currentGameRedux]);
  useEffect(() => {
    if (fullGameData !== null) {
      // console.log("fullGameData", fullGameData);
      const rows = document.querySelectorAll(".main-stats tbody tr");

      rows.forEach((row) => {
        const th = row.querySelector("th");
        row.addEventListener("mouseenter", () => {
          if (th) {
            th.classList.add("hovered");
          }
        });
        row.addEventListener("mouseleave", () => {
          if (th) {
            th.classList.remove("hovered");
          }
        });
      });
    }
  }, [fullGameData]);
  const formatDateForView = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  return (
    <>
      <div className="statistics-wrapper">
        <div className="title-wrapper">
          <div
            className="back"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            <IoMdArrowBack />
            <span>Back</span>
          </div>
          <div className="title">
            Poll Statistics for {currentGameRedux?.game_name}
          </div>
          <div className="export">
            <button>Export</button>
          </div>
        </div>
        {fullGameData !== null && (
          <>
            <div className="main-stats">
              <table>
                <tbody>
                  <tr>
                    <th>Game Name :</th>
                    <td>{fullGameData.gameName}</td>
                  </tr>
                  <tr>
                    <th>Home Team Name :</th>
                    <td>{fullGameData.homeTeamName}</td>
                  </tr>
                  <tr>
                    <th>Away Team Name :</th>
                    <td>{fullGameData.awayTeamName}</td>
                  </tr>
                  <tr>
                    <th>Goalkeeper Name Home Team :</th>
                    <td>{fullGameData.goalkeeper_home_team.name}</td>
                  </tr>
                  <tr>
                    <th>Goalkeeper Stats Home Team :</th>
                    <td>{`${fullGameData.goalkeeper_home_team.votes} votes (${fullGameData.goalkeeper_home_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Goalkeeper Name Away Team :</th>
                    <td>{fullGameData.goalKeeper_away_team.name}</td>
                  </tr>
                  <tr>
                    <th>Goalkeeper Stats Away Team :</th>
                    <td>{`${fullGameData.goalKeeper_away_team.votes} votes (${fullGameData.goalKeeper_away_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Defender Name Home Team :</th>
                    <td>{fullGameData.defender_home_team.name}</td>
                  </tr>
                  <tr>
                    <th>Defender Stats Home Team :</th>
                    <td>{`${fullGameData.defender_home_team.votes} votes (${fullGameData.defender_home_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Defender Name Away Team :</th>
                    <td>{fullGameData.defender_away_team.name}</td>
                  </tr>
                  <tr>
                    <th>Defender Stats Away Team :</th>
                    <td>{`${fullGameData.defender_away_team.votes} votes (${fullGameData.defender_away_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Midfielder Name Home Team :</th>
                    <td>{fullGameData.midfielder_home_team.name}</td>
                  </tr>
                  <tr>
                    <th>Midfielder Stats Home Team :</th>
                    <td>{`${fullGameData.midfielder_home_team.votes} votes (${fullGameData.midfielder_home_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Midfielder Name Away Team : </th>
                    <td>{fullGameData.midfielder_away_team.name}</td>
                  </tr>
                  <tr>
                    <th>Midfielder Stats Away Team :</th>
                    <td>{`${fullGameData.midfielder_away_team.votes} votes (${fullGameData.midfielder_away_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Striker Name Home Team :</th>
                    <td>{fullGameData.striker_home_team.name}</td>
                  </tr>
                  <tr>
                    <th>Striker Stats Home Team :</th>
                    <td>{`${fullGameData.striker_home_team.votes} votes (${fullGameData.striker_home_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Striker Name Away Team :</th>
                    <td>{fullGameData.striker_away_team.name}</td>
                  </tr>
                  <tr>
                    <th>Striker Stats Away Team :</th>
                    <td>{`${fullGameData.striker_away_team.votes} votes (${fullGameData.striker_away_team.percentage}%)`}</td>
                  </tr>
                  <tr>
                    <th>Total Votes :</th>
                    <td>{fullGameData.totalVotes}</td>
                  </tr>
                  <tr>
                    <th>Poll Ended :</th>
                    <td>{formatDateForView(fullGameData.pollEnded)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Statistics;
