import "./styles/deletionPopup.css";
import { useSelector, useDispatch } from "react-redux";
import { setDeletionPopup } from "../redux/reducer";
import gameService from "../service/gameService";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
const DeletionPopup = () => {
  const dispatch = useDispatch();
  const currentGame = useSelector((state) => state.main.currentGame);
  const type = useSelector((state) => state.main.deletionContext);
  const [gameName, setGameName] = useState("");
  useEffect(() => {
    if (currentGame?._id) {
      setGameName(currentGame.game_name);
    }
  }, [currentGame]);
  const handleDeletion = async () => {
    try {
      if (type === "game") {
        const response = await gameService.deleteGame(currentGame._id);

        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        dispatch(setDeletionPopup(false));
      } else if (type === "game and quiz") {
        const response = await gameService.deleteGameAndQuiz(currentGame._id);
        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        dispatch(setDeletionPopup(false));
      }
    } catch (err) {
      console.log(err, "error in deleting game");
    }
  };
  return (
    <>
      <div className="deletion-popup-wrapper">
        {type === "game" ? (
          <p>Are you sure you want to delete {gameName} ? </p>
        ) : (
          <p>Are you sure you want to delete {gameName} and its poll?</p>
        )}

        <div className="buttons-wrapper">
          <button
            className="cancel"
            onClick={() => dispatch(setDeletionPopup(false))}
          >
            Cancel
          </button>
          <button className="delete" onClick={handleDeletion}>
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default DeletionPopup;
