import { axiosBase, axiosPrintScreen } from "../axios/baseUrl";

const gameService = {
  createGame: async (data) => {
    try {
      const response = await axiosBase.post("/games/create", data);
      if (response.status === 200) {
        return response.data;
      }

      // Handle unexpected success status codes
      if (response.status >= 200 && response.status < 300) {
        console.warn(`Unexpected success status code: ${response.status}`);
        return response.data;
      }

      // If we reach here, it's an error case
      throw new Error(`HTTP error! status: ${response.status}`);
    } catch (error) {
      console.error(
        "Error creating game:",
        error.response?.data || error.message
      );

      if (error.response?.status === 400) {
        throw new Error("Invalid game data. Please check your inputs.");
      } else if (error.response?.status === 409) {
        throw new Error("A game with this identifier already exists.");
      } else {
        throw new Error("Failed to create game. Please try again.");
      }
    }
  },
  getAllGames: async () => {
    try {
      const response = await axiosBase.get("/games/allGames");
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  deleteGame: async (gameId) => {
    try {
      const response = await axiosBase.delete(`/games/delete/${gameId}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  deleteGameAndQuiz: async (gameId) => {
    try {
      const response = await axiosBase.delete(
        `/games/deleteGameAndQuiz/${gameId}`
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  editGame: async (gameId, data) => {
    try {
      const response = await axiosBase.post(`/games/edit/${gameId}`, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  activateGame: async (data) => {
    try {
      const response = await axiosBase.post("/games/activate", data);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  getGameStats: async (gameId) => {
    try {
      const response = await axiosBase.get(`/games/stats/${gameId}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  createScreenshot: async (identifier) => {
    console.log(axiosPrintScreen, "axiosPrintScreen");
    try {
      const response = await axiosPrintScreen.get(`${identifier}`);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
};

export default gameService;
