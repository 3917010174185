import "./styles/createEditAdmin.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import utils from "../utils/regex";
import { useSelector, useDispatch } from "react-redux";
import { setIsEdit } from "../redux/reducer";
import userService from "../service/userService";
import { toast } from "react-toastify";
const Register = ({ setView }) => {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isEdit = useSelector((state) => state.main.isEdit);
  const editContext = useSelector((state) => state.main.editContext);

  const currentUserRedux = useSelector((state) => state.main.currentUser);

  const [error, setError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [isActive, setIsActive] = useState(
    isEdit ? currentUserRedux.active : true
  );

  useEffect(() => {
    if (isEdit && editContext === "user") {
      // set user data to edit
      setUser({
        first_name: currentUserRedux.first_name,
        last_name: currentUserRedux.last_name,
        email: currentUserRedux.email,
      });
    }
  }, [currentUserRedux, isEdit]);

  const handleRegister = async () => {
    setDisableBtn(true);

    if (
      user.first_name === "" ||
      user.last_name === "" ||
      user.email === "" ||
      user.password === "" ||
      user.confirm_password === ""
    ) {
      setError("Please fill all the fields");
      setDisableBtn(false);
      return;
    }

    const validateEmail = utils.emailRegex.test(user.email);
    if (!validateEmail) {
      setError("Please enter a valid email");
      setDisableBtn(false);
      return;
    }

    if (user.password !== user.confirm_password) {
      setError("Passwords do not match");
      setDisableBtn(false);
      return;
    }
    try {
      const addUser = await userService.createUser(user);

      if (
        addUser?.status === 200 &&
        addUser?.data?.message === "User created invite sent"
      ) {
        toast.success("Admin Created! Invite Sent via Email");
        setView("games");
      } else if (
        addUser?.status === 200 &&
        addUser?.data?.message === "User already exists"
      ) {
        toast.error("This email is already registered.");
      }
    } catch (err) {
      console.log(err, "error in registration");

      console.log(err, "error in registration");

      // Handle Axios error with response
      if (err.response) {
        if (
          err.response.status === 400 &&
          err.response.data?.message === "User already exists"
        ) {
          toast.error(
            "This email is already registered. Please use a different email."
          );
        } else {
          toast.error(err.response.data?.message || "An error occurred");
        }
      } else {
        // Handle other types of errors (e.g., network issues)
        toast.error("An error occurred while processing your request.");
      }
    } finally {
      setDisableBtn(false);
    }
  };
  const handleChanges = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleEditAdmin = async () => {
    if (user.first_name === "" || user.last_name === "") {
      setError("Please fill all the fields");
      return;
    }
    if (
      user.first_name === currentUserRedux.first_name &&
      user.last_name === currentUserRedux.last_name &&
      isActive === currentUserRedux.active
    ) {
      setError("No changes were made");
      return;
    }
    try {
      const data = {
        first_name: user.first_name,
        last_name: user.last_name,
        active: isActive,
      };
      const response = await userService.editUser(currentUserRedux._id, data);
      if (response?.status === 200) {
        toast.success("Admin edited");
        setView("games");
        dispatch(setIsEdit(false));
      } else {
        toast.error(response?.data?.message || "An error occurred");
      }
    } catch (err) {
      console.log(err, "error in editing admin");
    }
  };
  return (
    <>
      <div className="register-wrapper">
        <h1>{isEdit ? "Edit Admin" : "Add Admin"}</h1>
        <div className="register-form">
          <div className="input-wrapper">
            <label htmlFor="first_name">First Name:</label>
            <input
              type="text"
              id="first_name"
              value={user.first_name}
              name="first_name"
              onChange={(e) => {
                setError("");
                handleChanges(e);
              }}
              required
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="last_name">Last Name:</label>
            <input
              onChange={(e) => {
                setError("");
                handleChanges(e);
              }}
              value={user.last_name}
              type="text"
              id="last_name"
              name="last_name"
              required
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="email">Email:</label>
            <input
              value={user.email}
              onChange={(e) => {
                setError("");
                handleChanges(e);
              }}
              type="email"
              id="email"
              name="email"
              required
              readOnly={isEdit}
            />
          </div>
          {!isEdit && (
            <>
              {" "}
              <div className="input-wrapper">
                <label htmlFor="password">Password:</label>
                <input
                  value={user.password}
                  onChange={(e) => {
                    setError("");
                    handleChanges(e);
                  }}
                  type="password"
                  id="password"
                  name="password"
                  required
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="password">Confirm Password:</label>
                <input
                  type="password"
                  onChange={(e) => {
                    setError("");
                    handleChanges(e);
                  }}
                  value={user.confirm_password}
                  id="confirm_password"
                  name="confirm_password"
                  required
                />
              </div>
            </>
          )}

          <div className="input-wrapper">
            <label htmlFor="team-status"> Status:</label>
            <div className="toggle-wrapper">
              <span
                className="toggle-status"
                style={{ color: isActive ? "#0d6efd" : "#fc0f0f" }}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
              <div className="toggle-switch">
                <input
                  type="checkbox"
                  id="team-status"
                  className="toggle-input"
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                />
                <label className="toggle-slider" htmlFor="team-status"></label>
              </div>
            </div>
          </div>

          {error && <div className="error">{error}</div>}
          <div className="input-wrapper">
            <button
              className="btn create-game"
              disabled={disableBtn}
              onClick={isEdit ? handleEditAdmin : handleRegister}
            >
              {isEdit ? "Submit" : "Register"}
            </button>
          </div>
          {/* <div className="login-link" onClick={() => navigate("/login")}>
            <p>
              Already have an account? <span>Login</span>
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Register;
