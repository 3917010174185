import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./styles/createEditGame.css";
import "./styles/createEditQuiz.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import quizService from "../service/quizService";
import { IoMdArrowBack } from "react-icons/io";

const CreateQuiz = () => {
  const [formData, setFormData] = useState({
    game_name: "",
    // game_date: "",
    // game_time: "",
    home_team_name: "",
    away_team_name: "",
    goalkeeper_home_team: "",
    goalkeeper_away_team: "",
    defender_home_team: "",
    defender_away_team: "",
    midfielder_home_team: "",
    midfielder_away_team: "",
    striker_home_team: "",
    striker_away_team: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [disableSubmission, setDisableSubmission] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const [oldQuiz, setOldQuiz] = useState({});
  const navigate = useNavigate();
  const isEdit = useSelector((state) => state.main.isEdit);
  const gameRedux = useSelector((state) => state.main.currentGame);
  const teamsRedux = useSelector((state) => state.main.allTeams);
  const handleChange = (e) => {
    setErrorMessage("");
    setErrorLocation("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    if (gameRedux?._id) {
      setSelectedGame(gameRedux);
    } else {
      navigate("/login");
    }
  }, [gameRedux]);

  useEffect(() => {
    console.log(selectedGame, "selectedGame");
    if (selectedGame?._id) {
      const homeTeamName =
        teamsRedux.find((team) => team._id === selectedGame.home_team)
          ?.team_name || "";

      const awayTeamName =
        teamsRedux.find((team) => team._id === selectedGame.away_team)
          ?.team_name || "";

      setFormData((prevData) => ({
        ...prevData,
        // game_name: selectedGame.game_name || "",
        // game_time: selectedGame.survey_activation_time || "",
        home_team_name: homeTeamName,
        away_team_name: awayTeamName,
      }));
      if (isEdit) {
        //  fetch quiz data and set it to form data
        const fetchQuiz = async () => {
          try {
            const quizData = await quizService.getQuizById(selectedGame._id);
            if (quizData.status === 200) {
              setOldQuiz(quizData.data);
              setFormData((prevData) => ({
                ...prevData,
                goalkeeper_home_team: quizData.data.goalkeeper_home_team || "",
                goalkeeper_away_team: quizData.data.goalkeeper_away_team || "",
                defender_home_team: quizData.data.defender_home_team || "",
                defender_away_team: quizData.data.defender_away_team || "",
                midfielder_home_team: quizData.data.midfielder_home_team || "",
                midfielder_away_team: quizData.data.midfielder_away_team || "",
                striker_home_team: quizData.data.striker_home_team || "",
                striker_away_team: quizData.data.striker_away_team || "",
              }));
            } else {
              toast.error(quizData.data.message);
            }
          } catch (err) {
            console.log(err, "error in fetching poll data");
          }
        };
        fetchQuiz();
      }
    }
  }, [selectedGame]);

  const createQuiz = async () => {
    setDisableSubmission(true);
    setErrorLocation("");
    setErrorMessage("");

    if (!formData.goalkeeper_home_team) {
      setErrorLocation("goalkeeper_home_team");
      setErrorMessage("Please fill Goalkeeper Home Team");
      setDisableSubmission(false);
      return;
    }

    if (!formData.goalkeeper_away_team) {
      setErrorLocation("goalkeeper_away_team");
      setErrorMessage("Please fill Goalkeeper Away Team");
      setDisableSubmission(false);

      return;
    }

    if (!formData.defender_home_team) {
      setErrorLocation("defender_home_team");
      setErrorMessage("Please fill Defender Home Team");
      setDisableSubmission(false);

      return;
    }
    if (!formData.defender_away_team) {
      setErrorLocation("defender_away_team");
      setErrorMessage("Please fill Defender Away Team");
      setDisableSubmission(false);

      return;
    }

    if (!formData.midfielder_home_team) {
      setErrorLocation("midfielder_home_team");
      setErrorMessage("Please fill Midfielder Home Team");
      setDisableSubmission(false);

      return;
    }

    if (!formData.midfielder_away_team) {
      setErrorLocation("midfielder_away_team");
      setErrorMessage("Please fill Midfielder Away Team");
      setDisableSubmission(false);

      return;
    }

    if (!formData.striker_home_team) {
      setErrorLocation("striker_home_team");
      setErrorMessage("Please fill Striker Home Team ");
      setDisableSubmission(false);

      return;
    }

    if (!formData.striker_away_team) {
      setErrorLocation("striker_away_team");
      setErrorMessage("Please fill Striker Away Team ");
      setDisableSubmission(false);
      return;
    }

    try {
      const data = {
        game_id: selectedGame._id,
        goalkeeper_home_team: formData.goalkeeper_home_team,
        goalkeeper_away_team: formData.goalkeeper_away_team,
        defender_home_team: formData.defender_home_team,
        defender_away_team: formData.defender_away_team,
        midfielder_home_team: formData.midfielder_home_team,
        midfielder_away_team: formData.midfielder_away_team,
        striker_home_team: formData.striker_home_team,
        striker_away_team: formData.striker_away_team,
      };
      const response = await quizService.createQuiz(data);
      console.log(response, "response332");
      if (
        response.status === 200 &&
        response?.data?.quizCreated &&
        response?.data?.gameStatusUpdated
      ) {
        toast.success("Poll Created");
        navigate("/dashboard");
        return;
      } else if (
        response?.status === 500 &&
        !response?.data?.gameStatusUpdated
      ) {
        toast.error("Quiz created but failed to update game status");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (err) {
      console.log(err, "error in creating poll");
    } finally {
      setDisableSubmission(false);
    }
  };
  const editQuiz = async () => {
    if (
      !formData.goalkeeper_home_team ||
      !formData.goalkeeper_away_team ||
      !formData.defender_home_team ||
      !formData.defender_away_team ||
      !formData.midfielder_home_team ||
      !formData.midfielder_away_team ||
      !formData.striker_home_team ||
      !formData.striker_away_team
    ) {
      setErrorMessage("Please fill all the fields");
      setErrorLocation("striker_away_team");
      return;
    }

    if (
      formData.goalkeeper_home_team === oldQuiz.goalkeeper_home_team &&
      formData.goalkeeper_away_team === oldQuiz.goalkeeper_away_team &&
      formData.defender_home_team === oldQuiz.defender_home_team &&
      formData.defender_away_team === oldQuiz.defender_away_team &&
      formData.midfielder_home_team === oldQuiz.midfielder_home_team &&
      formData.midfielder_away_team === oldQuiz.midfielder_away_team &&
      formData.striker_home_team === oldQuiz.striker_home_team &&
      formData.striker_away_team === oldQuiz.striker_away_team
    ) {
      setErrorMessage("No changes made");
      setErrorLocation("striker_away_team");
      return;
    }
    try {
      const data = {
        goalkeeper_home_team: formData.goalkeeper_home_team,
        goalkeeper_away_team: formData.goalkeeper_away_team,
        defender_home_team: formData.defender_home_team,
        defender_away_team: formData.defender_away_team,
        midfielder_home_team: formData.midfielder_home_team,
        midfielder_away_team: formData.midfielder_away_team,
        striker_home_team: formData.striker_home_team,
        striker_away_team: formData.striker_away_team,
      };
      const response = await quizService.editQuiz(oldQuiz._id, data);

      if (response.status === 200) {
        toast.success(response?.data?.message);
        navigate("/dashboard");
      } else {
        toast.error(response);
      }
    } catch (err) {
      console.log(err, "error in editing poll");
      throw err;
    }
  };
  return (
    <>
      <div className="create-game-wrapper create-quiz-wrapper">
        <div className="title">
          <div className="back" onClick={() => navigate("/dashboard")}>
            <IoMdArrowBack />
            <span style={{ paddingInlineStart: "10px" }}>Back</span>
          </div>
          <div className="page-title">
            {isEdit ? "Edit Poll" : "Create A New Poll"}
          </div>
        </div>

        <div className="game_form">
          <div className="input-wrapper">
            <label htmlFor="">Game Name:</label>
            <div style={{ width: "50%", color: "#fff" }}>
              {selectedGame?.game_name}
            </div>
            {/*<input*/}
            {/*  value={selectedGame?.game_name}*/}
            {/*  type="text"*/}
            {/*  readOnly*/}
            {/*  style={{ backgroundColor: "lightgray" }}*/}
            {/*/>*/}
          </div>

          {/* <div className="input-wrapper">
            <label htmlFor="">Game Time:</label>
            <input
              value={selectedGame.survey_activation_time}
              type="text"
              readOnly
              style={{ backgroundColor: "lightgray" }}
            />
          </div> */}

          <div className="input-wrapper">
            <label htmlFor="">Home Team:</label>
            <div style={{ width: "50%", color: "#fff" }}>
              {formData.home_team_name}
            </div>
            {/*<input*/}
            {/*  value={formData.home_team_name}*/}
            {/*  type="text"*/}
            {/*  readOnly*/}
            {/*  style={{ backgroundColor: "lightgray" }}*/}
            {/*/>*/}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Away Team:</label>
            <div style={{ width: "50%", color: "#fff" }}>
              {formData.away_team_name}
            </div>
            {/*<input*/}
            {/*  value={formData.away_team_name}*/}
            {/*  type="text"*/}
            {/*  readOnly*/}
            {/*  style={{ backgroundColor: "lightgray" }}*/}
            {/*/>*/}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Goalkeeper Home Team:</label>
            <input
              value={formData.goalkeeper_home_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="goalkeeper_home_team"
            />
            {errorLocation === "goalkeeper_home_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>

          <div className="input-wrapper">
            <label htmlFor="">Goalkeeper Away Team:</label>
            <input
              value={formData.goalkeeper_away_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="goalkeeper_away_team"
            />
            {errorLocation === "goalkeeper_away_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Defender Home Team:</label>
            <input
              value={formData.defender_home_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="defender_home_team"
            />
            {errorLocation === "defender_home_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Defender Away Team:</label>
            <input
              value={formData.defender_away_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="defender_away_team"
            />
            {errorLocation === "defender_away_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Midfielder Home Team:</label>
            <input
              value={formData.midfielder_home_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="midfielder_home_team"
            />
            {errorLocation === "midfielder_home_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Midfielder Away Team:</label>
            <input
              value={formData.midfielder_away_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="midfielder_away_team"
            />
            {errorLocation === "midfielder_away_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Striker Home Team:</label>
            <input
              value={formData.striker_home_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="striker_home_team"
            />
            {errorLocation === "striker_home_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          <div className="input-wrapper">
            <label htmlFor="">Striker Away Team:</label>
            <input
              value={formData.striker_away_team}
              onChange={(e) => handleChange(e)}
              type="text"
              name="striker_away_team"
            />
            {errorLocation === "striker_away_team" && (
              <div className="error">{errorMessage}</div>
            )}
          </div>
          {errorMessage && errorLocation !== "striker_away_team" && (
            <div className="error">One of the fields is missing</div>
          )}

          <div className="button-wrapper">
            <button
              disabled={disableSubmission}
              className="btn create-quiz"
              onClick={isEdit ? editQuiz : createQuiz}
            >
              {isEdit ? "Edit Poll" : "Create Poll"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuiz;
